import zh from './locales/zh.json'; 
import zhans from './locales/zh-hans.json'; 
import de from './locales/de.json'; 
import en from './locales/en.json'; 
import es from './locales/es.json';
import fr from './locales/fr.json';
import ja from './locales/ja.json';
import pl from './locales/pl.json';
import pt from './locales/pt-br.json';
import ptpt from './locales/pt-pt.json';
import ru from './locales/ru.json';
import it from './locales/it.json';

export default defineI18nConfig(() => ({
	legacy: false,
	silentFallbackWarn: true,
	silentTranslationWarn: true,
	fallbackWarn: false,
	missingWarn: false,
  messages: {
		zh, zhans, de, en, es, fr, ja, pl, pt, ptpt, ru, it
	}
}))